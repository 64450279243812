<template>
    <div class="content-wrapper">
        <div v-if="isList" class="content pt-0">
            <bo-page-title />
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                                </div>
                                <div class="col-md-8">
                                  <div class="form-group mb-0">
                                    <div class="input-group">
                                        <date-range-picker
                                            ref="picker"
                                            :locale-data="datePickerConfig.locale"
                                            :autoApply="datePickerConfig.autoApply"
                                            v-model="dateRange"
                                            :opens="'right'"
                                            :ranges="datePickerConfig.ranges"
                                            @update="updateValues"
                                        >
                                            <template v-slot:input="picker">
                                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                            </template>
                                        </date-range-picker>
                                        <div class="input-group-append calendar-group">
                                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row gx-1">
                                <div class="col-md">
                                    <div class="row gx-1">
                                        <div class="col-md-auto">
                                            <div class="form-group mb-0">
                                                <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right ><i class="icon-spinner11"></i></button>
                                            </div>
                                        </div>     
                                        
                                        <div class="col-md-4">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp"  :options="mDokterDPJP" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>      
                                        
                                        <div class="col-md-3">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Kelas -- " @input="doFill" v-model="filter.kelas"  :options="mKelas" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>  

                                        <div class="col-md-3">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder=" -- Pilih Ruangan -- " @input="doFill" v-model="filter.ruangan"  :options="mRuangan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>                                                             
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                        </div>
                    </div>
                </div>     

                     
                
                <div class="table-responsive">
                    <table
                        class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                        <thead>
                            <tr>
                                <th>NO.</th>
                                <th>NO. KEDATANGAN</th>
                                <th>NAMA/NO KTP</th>                              
                                <th>NO RM</th>
                                <th>RUANGAN</th>
                                <th>DOKTER DPJP</th>    
                                <th>STATUS</th>
                                <th>AKSI</th>
                            </tr>
                        </thead>
                        
                        <tbody v-if="(dataList||[]).length">
                            <template v-for="(v,k) in (dataList||[])">
                                <tr :key="k" :class="!v.aranr_ppjp && !v.aranr_perawat_pelaksana ? 'table-warning':''">
                                  <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                                  <td>
                                    <span class="font-weight-semibold">{{v.aranr_reg_code}}</span>
                                  </td>
                                  <td>
                                   <div class="d-flex flex-column align-items-start">
                                      <a href="javascript:;" @click="toModal(v)" class="font-weight-semibold border-bottom">{{v.ap_fullname||"-"}}</a>
                                      <small>{{v.ap_code}}</small>
                                      <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                                  </td>
                                  <td>{{getConfigDynamic(mRuangan,v.aranres_bangsal)||"-"}} </td>
                                  <td>{{getConfigDynamic(mDokterDPJP,v.aranr_dokter_id)||"-"}} </td>
                                 
                                  <td>
                                    <template v-if="v.aranpjt_status == 'WAITING'">
                                      <span class="badge bg-warning mb-1">Menunggu Hasil </span>
                                    </template>
                                    <template v-else>
                                      <span class="badge bg-success mb-1">Selesai </span>
                                    </template>
                                  </td>
                                  <td>
                                    <a href="javascript:;" @click="toModal(v)" v-if="moduleRole('view')" class="btn rounded-round btn-sm btn-icon alpha-blue border-blue" v-b-tooltip.hover title="Lihat Detail"><i class="icon-file-eye"></i></a>

                                    <a href="javascript:;" @click="toForm(v.aranpjt_id)" v-if="moduleRole('isi_hasil')" class="btn rounded-round btn-sm btn-icon alpha-success border-success" v-b-tooltip.hover :title="v.aranpjt_status == 'DONE' ? 'Ubah Hasil' : 'Isi Hasil'"><i class="icon-file-text3 text-success"></i></a>
                                  </td>
                                </tr>
                            </template>
                        </tbody>
                        <tbody v-if="data.data.length == 0">
                            <tr>
                                <td colspan="99">
                                <div class="text-center">
                                    <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="!data.data">   
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                        </tbody>
                    </table>

                    
                    <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                        <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-left"></i>
                        </a>
                        <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                        <i class="icon-chevron-right"></i>
                        </a>
                    </div>
                </div>
                
                <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
                    class="mb-0"
                    v-model="pageNo"
                    :per-page="data.per_page"
                    :total-rows="data.total"
                />
                </b-card-footer>

            </div>

            <b-modal
              v-model="openModal"
              :size="'lg'"
              no-close-on-backdrop
              :title="'Pemeriksaan Jaringan Tubuh'" 
            >
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Diterima Tanggal</label>
                    <p>{{ rowEdit.aranpjt_tanggal_diterima | moment("DD MMMM YYYY")}} {{rowEdit.aranpjt_waktu_diterima}}</p>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Tempat Rujukan</label>
                    <p>{{rowEdit.aranpjt_no||"-"}}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="bg_card_blue">
                    <table class="table table-borderless table-sm text-uppercase">
                      <tbody>
                        <tr>
                          <td width="33%">
                            <div class="result_tab">
                              <h4>No. Reg</h4>
                              <p>{{rowEdit.aranr_reg_code||"-"}}</p>
                            </div>
                          </td>
                          <td width="33%">
                            <div class="result_tab">
                              <h4>No. Rekam Medis</h4>
                              <p>{{rowEdit.ap_code||"-"}}</p>
                            </div>
                          </td>
                          <td width="33%">
                            <div class="result_tab">
                              <h4>Nama Pasien</h4>
                              <p>{{rowEdit.ap_fullname||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td width="33%">
                            <div class="result_tab">
                              <h4>Tempat, Tanggal Lahir </h4>
                              <p>{{rowEdit.ap_pob||"-"}}, 
                                <strong class="font-weight-semibold">{{rowEdit.ap_dob | moment("DD MMM YYYY")}}</strong>
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Usia</h4>
                              <p class="font-weight-semibold">{{rowEdit.ap_usia_with_ket || "-"}}<br />({{rowEdit.ap_gol_usia||"-"}})</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Jenis Kelamin</h4>
                              <p class="font-weight-semibold">{{rowEdit.ap_gender == 1 ? 'Laki-Laki' : 'Perempuan'}}</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td style="width=33%;">
                          <div class="result_tab">
                            <h4>Jaringan tubuh ini didapat dengan</h4>
                            <ul v-if="(rowEdit.aranpjt_jaringan_tubuh||[]).length">
                              <li v-for="(v,k) in (rowEdit.aranpjt_jaringan_tubuh||[])" :key="k">
                                <span v-if="v == 'Lainnya'">{{rowEdit.aranpjt_jaringan_tubuh_text||"-"}}</span>
                                <span v-else>{{v}}</span>
                              </li>
                            </ul>
                            <span v-else>Tidak ada Data</span>
                          </div>
                        </td>
                        <td style="width=33%;">
                          <div class="result_tab">
                            <h4>Lokasi Pengambilan</h4>
                            <p>{{rowEdit.aranpjt_berasal_dari_alat||"-"}}</p>
                          </div>
                        </td>
                        <td style="width=33%;">
                          <div class="result_tab">
                            <h4>Direndam dalam</h4>
                            <ul v-if="(rowEdit.aranpjt_direndam_dalam||[]).length">
                              <li v-for="(v,k) in (rowEdit.aranpjt_direndam_dalam||[])" :key="k">
                                <span v-if="v == 'Lainnya'">{{rowEdit.aranpjt_direndam_dalam_text||"-"}}</span>
                                <span v-else>{{v}}</span>
                              </li>
                            </ul>
                            <span v-else>Tidak ada Data</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="width=33%;">
                          <div class="result_tab">
                            <h4>Keterangan Klinis</h4>
                            <p>{{rowEdit.aranpjt_keterangan_klinis||"-"}}</p>
                          </div>
                          
                          <div class="result_tab mt-5">
                            <h4>Jaringan Keluar Dari Tubuh Jam</h4>
                            <p>{{rowEdit.aranpjt_tanggal_jaringan_keluar | moment("DD MMMM YYYY") }} {{rowEdit.aranpjt_jam_jaringan_keluar}}
                            </p>
                          </div>
                        </td>
                        
                        <td style="width=33%;">
                        
                        
                          <div class="result_tab">
                            <div class="mb-1" v-for="(v,k) in (dataICD10||[])" :key="k">
                            <h4>Diagnosis Klinis #{{k+1}} (ICD10)</h4>
                            <p>{{v.text||"-"}}</p>
                            </div>
                          </div>
                          <div class="result_tab">
                            <h4>Diagnosa Klinis <span v-if="(dataICD10||[]).length">Lainnya</span></h4>
                            <p>{{rowEdit.aranpjt_diagnosa_klinis||"-"}}</p>
                          </div>
                        </td>

                        <td style="width=33%;">
                          <div class="result_tab">
                            <h4>Tanda Tangan</h4>
                            <img style="width: 100%;height: 160px;" :src="rowEdit.aranpjt_ttd" />
                            <p>{{rowEdit.aranpjt_nama_ttd||"-"}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
                    
              <template #modal-footer>
                <b-button variant="primary" v-if="rowEdit.aranpjt_id" @click="downloadLaporan(rowEdit.aranpjt_arano_id)">
                  Unduh Laporan
                </b-button>
              </template>
            </b-modal>
        </div>
        <template v-else>
          <Form />
        </template>
    </div>
</template>


<script>
  import GlobalVue from '@/libs/Global.vue'
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
  const moment = require('moment')
  import Form from './Form.vue'

  const _ = global._
  import $ from 'jquery'
  import Gen from '@/libs/Gen.js'
  export default {
    extends: GlobalVue,
    components:{
      DateRangePicker,Form
    },
    data(){
      return {
        idKey:'aranres_id',
        datePickerConfig: {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
          autoApply: true,
          ranges: {
              'Hari Ini': [new Date(), new Date()],
              '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
              '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
          },
          applyClass: 'btn-sm btn-primary',
          cancelClass: 'btn-sm btn-light',
          locale: {
              applyLabel: 'Terapkan',
              cancelLabel: 'Batal',
              direction: 'ltr',
              format: 'mm/dd/yyyy',
              separator: ' - ',
          }
        },
        dateRange: {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
        },
        mDokterDPJP: [],
        mKelas: [],
        mRuangan: [],

        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false,
        dataICD10: [],
      }
    },
    // computed:{
    //   isDetail(){
    //     return this.$route.query.isDetail
    //   },
    // },
    methods: {
      toForm(id){
        this.$router.push({ name: this.modulePage, params: {pageSlug: id}}).catch(()=>{})
      },
      toDetail(regID){
        this.$router.push({ name: 'RanapPasienTindakanLaboratorium', params: {pageSlug: regID}}).catch(()=>{})
      },
      setSlide(){
        const responsiveTable = document.querySelector('.table-responsive')
        if(responsiveTable){
          const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
          const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
          if(responsiveTableScroll){
            // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
            Array.from(responsiveTableScrollActions).forEach(action => {
              action.addEventListener('click', e => {
                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                  
                responsiveTable.scroll({
                    left: scrollAmount,
                    behavior: 'smooth'
                })
              })
            })
          }
        }
      },
      doResetData(){
        this.doReset()
        this.dateRange =  {
          startDate: new Date(moment().subtract(6, 'days')),
          endDate: new Date(),
        }
        this.doConvertDate()
      },
      doConvertDate(){
        this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
        this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      },
      doPageOne(){
        this.filter.page = 1
      },
      doSearch: _.debounce(function(){
        this.doPageOne()
        this.doFilter()
      },500),
      doFill(){
        this.doPageOne()
        this.doFilter()
      },
      updateValues(e){
        this.doConvertDate()
        this.doFill()
      },
      doRefreshData(){
        this.apiGet()
        window.scrollTo(0,0)
      },
      getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
      },
      toModal(row){
        this.rowEdit = row
        this.openModal = true

        this.loadingOverlay = true
        let url = 'RanapICD10Operasi'+'/'+row.aranpjt_arano_id+'?typeOperasi='+'KajianPAKlinis'      
        Gen.apiRest(
          "/get/"+url
        ).then(res=>{
          this.loadingOverlay = false
          console.log(res)
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
        }).catch(()=>{
          this.loadingOverlay = false
        })

      },
      downloadLaporan(operasiID){
        let data = {exptype: 'xlsx', type: "export", id: operasiID}
        let self = this
        $.ajax({
            type: "POST",
            url: process.env.VUE_APP_API_URL + `/report/${'pemeriksaanJaringanTubuh'}?token=IXs1029102asoaksoas102901290`,
            data: data,
            cache: false,
            xhrFields:{
                responseType: 'blob'
            },
            success: data => 
            {
                self.loadingOverlay = false
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(data)
                link.download = `Pemeriksaan-Jaringan-Tubuh-${moment().format("YYYY-MM-DD")}.pdf`
                link.click()
            },
            fail: data => {
                self.loadingOverlay = false
                alert('Not downloaded')
            }
        })
      },
    },
    mounted(){
      this.filter.dpjp = 'ALL'
      this.filter.kelas = 'ALL'
      this.filter.ruangan = 'ALL'
    
      this.doConvertDate()      
      this.apiGet()
          
      setTimeout(()=>{
        this.setSlide()
      },1000)
    },
    filters: {
      date(val) {
        return val ? moment(val).format("D MMM YYYY") : ""
      }
    },
    watch:{
      $route(){
        this.apiGet()
        setTimeout(()=>{
          if(this.isList){
            this.setSlide()
          }
        },1000)
      }
    }
  }

</script>
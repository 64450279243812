<template>
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Registrasi">{{rowReg.aranr_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        <div class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        <div class="content">
            <validation-observer ref="VForm">
                <b-form @submit.prevent="doSubmit">
                    <div class="card">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">UPLOAD HASIL PEMERIKSAAN JARINGAN TUBUH</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">

                        <div class="col-md-12">
                            <table class="table table-bordered table-striped table-sm patient-table">
                            <thead>
                                <tr>
                                <th>Hasil Pemeriksaan </th>
                                <th>Upload Hasil</th>
                                <th>Nilai Kritis</th>
                                <th>Jam Keluar Hasil Kritis</th>
                                <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(v,k) in (row.aranpjt_hasil||[])" :key="k+'hasil'">
                                <td>
                                    <b-form-textarea v-model="row.aranpjt_hasil[k]['value']" type="text" name="name" class="form-control" />
                                <VValidate :name="'Hasil Pemeriksaan #'+(k+1)" v-model="row.aranpjt_hasil[k]['value']"
                                    :rules="{required:1, min:2}" />
                                </td>
                                <td>
                                    <Uploader v-model="row.aranpjt_hasil[k]['file']" isDocument type="docimage" />
                                    <VValidate :name="'File #'+(k+1)" v-model="row.aranpjt_hasil[k]['file']"
                                        :rules="{required:1}" />
                                </td>
                                <td>
                                    <b-form-textarea v-model="row.aranpjt_hasil[k]['nilai_kritis']" type="text" name="name" class="form-control" />
                                </td>
                                <td>                            
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.aranpjt_hasil[k]['jam_keluar']">
                                        </vue-timepicker>
                                    </div>
                                    <VValidate v-if="row.aranpjt_hasil[k]['nilai_kritis']" :name="'Jam Keluar Hasil #'+(k+1)" v-model="row.aranpjt_hasil[k]['jam_keluar']"
                                    :rules="{required:1}" />
                                <td> 
                                <a href="javascript:;" class="list-icons-item"
                                    @click="row.aranpjt_hasil.splice(k,1)"
                                    data-toggle="tooltip" data-placement="top" title="Delete"><i
                                    class="icon-bin"></i></a>
                                </td>
                                </tr>
                                <tr v-if="!(row.aranpjt_hasil||[]).length">
                                    <td colspan="99" class="text-center">Tidak Ada Data</td>
                                </tr>
                            </tbody>
                            </table>

                            <div class="more_data">
                            <a href="javascript:;" @click="addNew"><i class="icon-plus-circle2"></i>
                                Tambah</a>
                            </div>
                        </div>

                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="text-right">
                            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                        </div>
                    </div>
                    </div>
                </b-form>
            </validation-observer>
        </div>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
const moment = require('moment')
import Gen from '@/libs/Gen.js'

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    extends: GlobalVue,
    data(){
      return {
        rowReg: {},
        dataDokter: {},
        showPatient: false,
        openLab: false,
        activeTabLab: 0,
      }
    },
    computed: {  
      canEditLab() {
        return true
      },
    },
    components:{ 
        VueTimepicker
    },
    methods: {
        back(){
            this.$router.back()
        },
        apiGet(params = {}, page = 1){
            if(!this.pageSlug){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }

            if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
            this.data.data = false
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            let url = this.modulePage+'/'+this.$route.params.pageSlug
            
            Gen.apiRest(
                "/get/"+url, 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.pageNow = page
                this.last_page = this.data.last_page
                
                if(!this.isFound){
                    this.$router.push({name : 'Dashboard'}).catch(()=>{})
                }
            }).catch(()=>{
                this.loadingOverlay = false
            })
            
        },
        initSticky(){
            const PageHeaderSticky = document.querySelector('.page-header-sticky')
            if(PageHeaderSticky){    
                const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                    PageHeaderToggle.addEventListener('click', function(e){
                    // e.preventDefault()
                    PageHeaderSticky.classList.toggle('page-header-sticky-open')
                    if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                        PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                    }else{
                        PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                    }
                    })    
                } 
            }
        },

        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },

        doSubmit(){
            this.$refs['VForm'].validate().then(success=>{
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                         setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                
                if(!(this.row.aranpjt_hasil||[]).length){
                    return this.$swal({
                        icon: 'error',
                        title: 'Tidak ada Hasil Lab, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                if(el[i].style.display !== 'none'){
                                    inv.push(el[i].id)
                                }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }

                 if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data'
                            this.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+this.modulePage,
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    if (result.value) {
                                      this.$router.push({ name: this.modulePage })
                                    }
                                })
                            }).catch(err=>{
                                this.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })

                

        },

        autoSave: _.debounce(function (data) {
          data.type = 'auto-save'
          Gen.apiRest(
              "/do/"+this.modulePage,
              {data:data}, 
              "POST"
          )
        },1000),

        addNew(){
            this.row.aranpjt_hasil.push({
                value: null,
                file: null,
                dokter:null,
                nilai_kritis: null,
                jam_keluar: '',
                dilaporkan_oleh: null,
                dilaporkan_pada: null
            })
        },
        toValidate(val){
            return {...val}
        },

        // copas dari ssini
    },
    mounted() {
        setTimeout(()=>{
            this.initSticky()
        },1000)
        document.body.classList.add('sidebar-xs')
        this.apiGet()
    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
    }
}
</script>
